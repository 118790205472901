<template>
    <div class="breadcrumb-area bg-img" :style="{'background-image':'url(/assets/img/backgrounds/Breadcrumb-003.png)'}">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="page-banner text-center">
                        <h1 class="bread__text">{{ title }}</h1>
                        <!-- <b-breadcrumb class="page-breadcrumb" :items="items"></b-breadcrumb> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        // props: ['items', 'title']
        props: ['title']
    }
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@600&display=swap');

.bread__text {
    /* text-transform: uppercase; */
    /* font-style: italic; */
    font-family: 'Poppins', serif;
}
</style>
