<template>
  <div class="font-card">
    <b-card bg-variant="light" align="center">
      <blockquote class="card-blockquote">
          <h1>No projects yet</h1>
          <div class="img-span">
              <b-img class="img" src="./assets/img/projects/folder-img.png"></b-img>
          </div>
          <footer>
              <p>Click the red button to see more projects</p>
              <router-link @click.native="scrollToTop" to="/project-completed" class="ht-btn ht-btn--round">Project</router-link>
          </footer>
      </blockquote>
    </b-card>
  </div>
</template>

<script>
export default {
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0)
        }
    }
}
</script>

<style>
.font-card .card-blockquote h1 {
    font-family: 'Poppins', serif;
}

.img-span {
    margin-top: 60px;
    margin-bottom: 50px;
}

.img {
    width: 200px;
    height: 200px;
}

.ht-btn--round{
    display: inline-block;
    font-size: 14px;
    line-height: 2;
    padding: 10px 40px;
    font-weight: 400;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #ed1d25;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
}
</style>
